<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>
          Petty Cash {{ petty_cash.petty_cash.venue_name || "" }}
        </h1></template
      >
      <template v-slot:item-slot>
        <router-link class="breadcrumb-item" :to="{ name: 'PettyCash' }"
          >Petty Cash</router-link
        >
        <div class="breadcrumb-item">
          {{ petty_cash.petty_cash.venue_name || "" }}
        </div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">{{ petty_cash.petty_cash.venue_name }}</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Detail</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Venue</label>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  :value="petty_cash.petty_cash.venue_name"
                />
              </div>
              <div
                v-for="(fragment, index) in petty_cash.petty_cash_fragment"
                :key="index"
                class="mb-2"
              >
                <div class="row" v-if="false">
                  <div class="col-5">
                    <div class="btn btn-success w-100">
                      Rp. {{ fragment.nominal }} x {{ fragment.qty }}
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="btn btn-primary">=</div>
                  </div>
                  <div class="col-5">
                    <div class="btn btn-info w-100 text-left">
                      Rp. {{ formatPrice(fragment.nominal_qty) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-group mt-3"
                v-for="nominal in petty_cash.petty_cash.nominals"
                :key="nominal.name"
              >
                <label> {{ nominal.name.toLowerCase().toUpperCase() }} </label>
                <h5>Rp. {{ formatPrice(nominal.nominal) }}</h5>
              </div>
              <hr />
              <div class="form-group mt-3">
                <label>Total Amount</label>
                <h4>
                  Rp. {{ formatPrice(petty_cash.petty_cash.total_amount) }}
                </h4>
              </div>
            </div>
          </div>
          <div class="card">
            <export-pdf :payments="paymentTypes"></export-pdf>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Form</h4>
            </div>
            <form @submit.prevent="pettyCashInsert">
              <div class="card-body">
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="form.is_closing"
                      id="form-check-is-closing"
                      @click="isClosingTrue"
                    />
                    <label class="form-check-label" for="form-check-is-closing">
                      Tutup Buku?
                    </label>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Amount</label>
                      <money
                        v-model="form.nominal"
                        class="form-control"
                        :disabled="
                          !form.is_closing == false || !form.is_closing == 0
                        "
                      ></money>
                      <small class="text-danger" v-if="errors.nominal">{{
                        errors.nominal[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Status</label>
                      <select
                        class="form-control"
                        v-model="form.status"
                        v-if="form.is_closing != true"
                      >
                        <option value="">Select Status</option>
                        <option value="IN">IN</option>
                        <option value="OUT">OUT</option>
                      </select>
                      <select
                        class="form-control"
                        v-model="form.status"
                        v-else
                        disabled
                      >
                        <option value="OUT" selected>OUT</option>
                      </select>
                      <small class="text-danger" v-if="errors.status">{{
                        errors.status[0]
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="form-group d-none">
                  <label>Fragment Total</label>
                  <input
                    type="number"
                    v-model="form.nominal_fragment"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="">Payment Type</label>
                  <select
                    class="form-control"
                    v-model="form.payment_type_id"
                    @change="getAmountPayment"
                  >
                    <option value="">--SELECT PAYMENT TYPE--</option>
                    <option
                      v-for="paymentType in paymentTypes"
                      :value="paymentType.id"
                      :key="paymentType.id"
                      >{{ paymentType.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group">
                  <label>Note</label>
                  <textarea v-model="form.note" class="form-control"></textarea>
                  <small class="text-danger" v-if="errors.note">{{
                    errors.note[0]
                  }}</small>
                </div>
                <div
                  class="form-group"
                  v-if="form.is_closing && form.payment_type_id == 2"
                >
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 100.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[0].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 50.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[1].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 20.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[2].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 10.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[3].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 5.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[4].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 2.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[5].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">
                          Rp. 1.000
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[6].qty"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label>Pecahan</label>
                        <div class="btn btn-primary form-control">Rp. 500</div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Jumlah</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="form.fragment[7].qty"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4>History</h4>
          <div class="card-header-form">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="search"
                label="Search"
              />
              <div class="input-group-btn">
                <button class="btn btn-primary">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <v-progress-circular
            v-if="loading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-data-table
            :headers="headers"
            :items="petty_cash.petty_cash_log"
            item-key="id"
            :search="search"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="elevation-1"
            @page-count="pageCount = $event"
            v-else
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.created_at }}</td>
                <td>{{ row.item.created_by_name }}</td>
                <td>{{ row.item.payment_type_name }}</td>
                <td>Rp. {{ formatPrice(row.item.nominal) }}</td>
                <td>Rp. {{ formatPrice(row.item.change) }}</td>
                <td>{{ row.item.note }}</td>
                <td>
                  <div
                    class="badge"
                    :class="{
                      'badge-success': row.item.status == 'IN',
                      'badge-danger': row.item.status == 'OUT',
                    }"
                  >
                    {{ row.item.status }}
                  </div>
                </td>
                <td>
                  <button
                    class="badge badge-success mr-1"
                    @click="print(row.item.id)"
                  >
                    Export PDF
                  </button>
                  <router-link
                    :to="{
                      name: 'PettyCashUpload',
                      params: { id: row.item.id },
                    }"
                    class="badge badge-info mr-1"
                    >Upload File
                  </router-link>
                  <router-link
                    :to="{
                      name: 'PettyCashLog',
                      params: { id: row.item.id },
                    }"
                    class="badge badge-primary mr-1"
                    >Detail
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { PaymentTypeRepository } from "../../repositories/RepositoryFactory";
import ExportPdf from "./component/ExportPdf.vue";
import { apiGet } from "../../services/api";
export default {
  name: "PettyCashShow",
  components: { ExportPdf },
  data() {
    return {
      petty_cash: [],
      errors: [],
      expanded: [],
      paymentTypes: [],
      form: {
        nominal: "",
        status: "",
        note: "",
        venue_id: this.$route.params.id,
        is_closing: 0,
        payment_type_id: "",
        fragment: [
          {
            nominal: 100000,
            qty: 0,
          },
          {
            nominal: 50000,
            qty: 0,
          },
          {
            nominal: 20000,
            qty: 0,
          },
          {
            nominal: 10000,
            qty: 0,
          },
          {
            nominal: 5000,
            qty: 0,
          },
          {
            nominal: 2000,
            qty: 0,
          },
          {
            nominal: 1000,
            qty: 0,
          },
          {
            nominal: 500,
            qty: 0,
          },
        ],
        nominal_fragment: 0,
      },
      form_export: {
        start_at: "",
        end_at: "",
        payment_type_id: "",
      },
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      isSubmitting: false,
    };
  },
  created() {
    this.getData();
    this.getPayment();
  },
  updated() {
    if (this.form.is_closing == true && this.form.payment_type_id == 2) {
      this.form.nominal = this.total_amount;
    }
  },
  methods: {
    isClosingTrue() {
      this.form.status = "OUT";
      this.form.nominal_fragment = 0;
      apiGet("petty_cash/get_nominal", {
        payment_type_id: this.form.payment_type_id,
        venue_id: this.form.venue_id,
      }).then((res) => {
        this.form.nominal = res.data.data.nominal;
        this.form.nominal_fragment = res.data.data.nominal;
      });
    },
    getPayment() {
      PaymentTypeRepository.get().then((res) => {
        this.paymentTypes = res.data.data;
      });
    },
    getData() {
      let id = this.$route.params.id;
      axios
        .get(this.$store.state.api + "petty_cash/" + id, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => (this.petty_cash = res.data.data))
        .catch((err) => console.log(err));
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    pettyCashInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "petty_cash/store", this.form, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your Petty Cash has been created!");
          this.isSubmitting = false;
          this.getData();
          this.errors = "";
          this.form.nominal = 0;
          this.form.note = "";
          this.form.status = "";
          this.form.is_closing = false;
          this.form.payment_type_id = "";
          this.form.nominal_fragment = 0;
          for (let index = 0; index < 7; index++) {
            this.form.fragment[index].qty = 0;
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    print(id) {
      fetch(this.$store.state.api + "petty_cash/download/" + id, {
        method: "GET",
        headers: {
          Authorization: this.$store.state.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "petty-cash.pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    },
    export_pdf() {
      axios({
        url: this.$store.state.api + "petty_cash/export",
        method: "GET",
        responseType: "blob",
        Authorization: this.$store.state.token,
        params: {
          start_at: this.form_export.start_at,
          end_at: this.form_export.end_at,
          payment_type_id: this.form_export.payment_type_id,
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "petty_cash.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    getAmountPayment() {
      if (this.form.is_closing && this.form.status == "OUT") {
        apiGet("petty_cash/get_nominal", {
          payment_type_id: this.form.payment_type_id,
          venue_id: this.form.venue_id,
        }).then((res) => {
          this.form.nominal = res.data.data.nominal;
          this.form.nominal_fragment = res.data.data.nominal;
        });
      } else {
        apiGet("petty_cash/get_nominal", {
          payment_type_id: this.form.payment_type_id,
          venue_id: this.form.venue_id,
        }).then((res) => (this.form.nominal_fragment = res.data.data.nominal));
      }
    },
  },
  computed: {
    total_amount() {
      const one = this.form.fragment[0].nominal * this.form.fragment[0].qty;
      const two = this.form.fragment[1].nominal * this.form.fragment[1].qty;
      const three = this.form.fragment[2].nominal * this.form.fragment[2].qty;
      const four = this.form.fragment[3].nominal * this.form.fragment[3].qty;
      const five = this.form.fragment[4].nominal * this.form.fragment[4].qty;
      const six = this.form.fragment[5].nominal * this.form.fragment[5].qty;
      const seven = this.form.fragment[6].nominal * this.form.fragment[6].qty;
      const eight = this.form.fragment[7].nominal * this.form.fragment[7].qty;
      return one + two + three + four + five + six + seven + eight;
    },
    headers() {
      return [
        {
          text: "Created At",
          value: "created_at",
        },
        {
          text: "Created By",
          value: "created_by_name",
        },
        {
          text: "Payment Type",
          value: "payment_type_name",
        },
        {
          text: "Amount",
          value: "nominal",
        },
        {
          text: "Change",
          value: "change",
        },
        {
          text: "Note",
          value: "note",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
        },
      ];
    },
  },
};
</script>
